<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h6">
          {{ $t("labels.add_license_assign") }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="item.id_country"
              class="c-input-small"
              outlined
              dense
              hide-details
              :label="$t('labels.country')"
              :placeholder="$t('labels.country')"
              :items="countries"
              @change="getObjectAndLicenses"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="item.classify"
              :disabled="!item.id_country"
              class="c-input-small"
              outlined
              dense
              hide-details
              :label="$t('labels.classify')"
              :placeholder="$t('labels.classify')"
              :items="classifyOptions"
              @change="getObjectAndLicenses"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="item.type"
              :disabled="!item.classify"
              class="c-input-small"
              outlined
              dense
              hide-details
              :label="$t('labels.administer')"
              :placeholder="$t('labels.administer')"
              :items="typeOptions"
              @change="getObjectAndLicenses"
            ></v-autocomplete>
          </v-col>

          <template v-if="!!item.id_country && !!item.classify && !!item.type">
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="item.id_object"
                class="c-input-small"
                outlined
                dense
                hide-details
                :label="$t('labels.object')"
                :placeholder="$t('labels.object')"
                :items="objectOptions"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="item.id_license"
                class="c-input-small"
                outlined
                dense
                hide-details
                :label="$t('labels.function')"
                :placeholder="$t('labels.function')"
                :items="licenseOptions"
                :disabled="!item.id_object"
                @change="onLicenseChanged"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="item.unit"
                class="c-input-small"
                outlined
                dense
                hide-details
                :label="$t('labels.unit')"
                :placeholder="$t('labels.unit')"
                :items="unitOptions"
                disabled
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <date-picker-model
                v-model="item.start_time"
                custom-class="c-input-small"
                :label="$t('labels.started_date')"
                :single-line="false"
                :disabled="!item.is_limit || !item.id_license"
                @change="onStartDateChanged"
              ></date-picker-model>
            </v-col>

            <v-col cols="12" md="6">
              <!-- :disabled="!item.is_limit || !item.id_license" -->
              <date-picker-model
                v-model="item.end_time"
                :key="item.start_time"
                custom-class="c-input-small"
                :label="$t('labels.expired_date')"
                :single-line="false"
                disabled
              ></date-picker-model>
            </v-col>

            <v-col cols="12" md="6">
              <input-number
                v-model="item.price"
                :key="item.id_license"
                outlined
                dense
                hide-details
                disabled
                class="c-input-small"
                :label="$t('labels.price')"
                :placeholder="$t('labels.price')"
              />
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="item.vat"
                class="c-input-small"
                outlined
                dense
                hide-details
                disabled
                :label="$t('labels.vat')"
                :placeholder="$t('labels.vat')"
                :items="vatOptions"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="12">
              <v-textarea
                v-model="item.notify_email"
                class="c-input-small"
                outlined
                dense
                hide-details
                rows="3"
                :label="$t('labels.notify_email')"
                :placeholder="$t('labels.notify_email')"
              ></v-textarea>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="cancel">
          {{ $t("labels.cancel") }}
        </v-btn>
        <v-btn color="green darken-1" text @click="confirm">
          {{ $t("labels.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import { httpClient } from "@/libs/http";
import {
  LICENSE_PRICE_STATUS_OPTIONS,
  LICENSE_PRICE_UNIT_OPTIONS,
  LICENSE_PRICE_TYPE_OPTIONS,
  LICENSE_PRICE_CLASSIFY_OPTIONS,
  VAT_OPTIONS,
  LICENSE_UNIT_LIMITS,
  LICENSE_UNIT_LIMIT_DETAIL,
} from "@/libs/const";
export default {
  components: {},
  name: "AssignCreate",
  props: {},
  data: () => ({
    isLoading: false,
    item: {
      id_country: null,
      classify: null,
      type: null,
      id_object: null,
      id_license: null,
      price: null,
      vat: null,
      start_time: null,
      end_time: null,
      notify_email: null,
      relate_type: null,
      relate_id_object: null,
      is_limit: false,
    },
    licenses: [],
    objectOptions: [],
    statusOptions: [...LICENSE_PRICE_STATUS_OPTIONS],
    typeOptions: [...LICENSE_PRICE_TYPE_OPTIONS],
    classifyOptions: [...LICENSE_PRICE_CLASSIFY_OPTIONS],
    unitOptions: [...LICENSE_PRICE_UNIT_OPTIONS],
    unitLimits: [...LICENSE_UNIT_LIMITS],
    unitLimitDetail: { ...LICENSE_UNIT_LIMIT_DETAIL },
    vatOptions: [...VAT_OPTIONS],
    countries: [],
  }),
  computed: {
    licenseOptions() {
      return [...this.licenses].map((lc) => ({
        text: `${lc.name} ${lc.note ? " - " + lc.note : ""}`,
        value: lc.id,
      }));
    },
  },
  created() {},
  mounted() {
    this.getCountries();
  },
  methods: {
    async getCountries() {
      const { data } = await httpClient.post("/common/v1/get-country");
      this.countries = data.map((e) => ({
        value: e.id,
        text: this.capitalizeStr(e.name) || "",
      }));
    },
    onLicenseChanged() {
      const license = [...this.licenses].find(
        (lc) => lc.id == this.item.id_license
      );
      if (license) {
        this.item = {
          ...this.item,
          unit: license.unit,
          price: +license.price,
          vat: license.vat,
          is_limit: this.unitLimits.includes(license.unit),
        };
      }
    },
    onStartDateChanged() {
      const license = [...this.licenses].find(
        (lc) => lc.id == this.item.id_license
      );
      if (license && license.unit && this.unitLimits.includes(license.unit)) {
        const unitDetail = this.unitLimitDetail[license.unit];
        const end_time = moment(this.item.start_time)
          .add(unitDetail.add_amount, unitDetail.add_type)
          .subtract(unitDetail.sub_amount, unitDetail.sub_type)
          .format("YYYY-MM-DD");
        this.item = { ...this.item, end_time };
      }
    },
    getObjectAndLicenses() {
      if (!this.item.id_country || !this.item.classify || !this.item.type) {
        this.licenses = [];
        this.objectOptions = [];
        return false;
      }

      httpClient
        .post("/license/v1/get-active-license", this.item)
        .then(({ data }) => {
          this.licenses = [...data];
        });

      httpClient.post("/license/v1/get-object", this.item).then(({ data }) => {
        this.objectOptions = [...data];
      });
    },
    cancel() {
      this.$emit("cancel");
    },
    refreshData() {
      this.$emit("refreshData");
    },
    async confirm() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/license/v1/assign-save", this.item);
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.refreshData();
        this.cancel();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>
